<template>
    <div class="contract">

        <h1 class="contract__organization">{{user.uf_name }}</h1>
        <div class="contract__info">
            <p>Договор: {{user.uf_contract}}</p>
            <p v-if="user.uf_request_confirm_date">Дата договора: {{user.uf_request_confirm_date}}</p>
            <p>УНП: {{user.uf_unp}}</p>
            <p>Email: {{user.email}}</p>
        </div>
        <div class="contract__links-wrapper">
            <h2 class="contract__block-title">Ссылки</h2>
            <div class="contract__links">
                <router-link class="contract__link contract__link_color_green" tag="a" :to="{name:'ContractEdit', params:{id:id}}">
                    Редактировать <i class="fas fa-arrow-right ml-2"></i>
                </router-link>
                <router-link class="contract__link " tag="a" :to="{name:'TransportationsAdmin', params:{id:id},  user:user}">
                    Перевозки <i class="fas fa-arrow-right ml-2"></i>
                </router-link>
                <router-link class="contract__link " tag="a"  :to="{name:'ClientPaymentsAdmin',  params:{id:id}, user:user}">
                    Платежи <i class="fas fa-arrow-right ml-2"></i>
                </router-link>
                <router-link class="contract__link " tag="a"  :to="{name:'ClientCarsAdmin', params:{id:id}, user:user}">
                    Транспорт <i class="fas fa-arrow-right ml-2"></i>
                </router-link>
              <router-link class="contract__link " tag="a"  :to="{name:'ClientRegistrationsAdmin', params:{id:id}, user:user}">
                Регистрации <i class="fas fa-arrow-right ml-2"></i>
              </router-link>
            </div>
        </div>


        <div class="contract__actions-wrapper">
            <h2 class="contract__block-title">Действия</h2>
            <div class="contract__actions">
                <SendLoginAndPass :id="id"></SendLoginAndPass>
                <div class="request__actions" v-if=" Number.parseInt(user.uf_client_status) === 1 ">
                    <button class="request__action request__action_confirm" @click="openModal('confirm')">
                      Принять
                    </button>
                    <button class="request__action request__action_reject" @click="openModal('reject')">
                      Отклонить
                    </button>
                    <ContractsRequestsModal :id="id" :show-modal.sync="showRequestModal" :type="type"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import SendLoginAndPass from '@/components/user/admin/SendLoginAndPass';
import ContractsRequestsModal from '@/components/user/admin/ContractsRequestsModal'
export default {
    name: "ContractDetail",
    components:{
        SendLoginAndPass,
      ContractsRequestsModal
    },
    data:()=>({
       id:null,
       user:{},
        showRequestModal:false,
        type:""
    }),
    created() {
        this.id = this.$route.params.id
        this.fetchInfo()
    },
    methods:{
      fetchInfo(){
          this.$store.dispatch('admin/getContractSingle', {id: this.id})
            .then(res  => {
               this.user = res.data.data
            }).catch(err => {
              this.$notify({
                  title: 'Ошибка',
                  text: err.response.data.message,
                  type: 'error'
              })
          })
      },
      openModal(type){
        this.type = type
        this.showRequestModal = true
      }

    }
}
</script>

<style scoped>
.contract{
    @apply lg:container mx-auto
}
.contract__info{
    @apply text-lg my-5 rounded p-10 dark:bg-gray-800 bg-gray-200
}
.contract__organization{
    @apply text-2xl my-5
}
.contract__links{
    @apply flex sm:flex-col lg:flex-row my-5 text-white
}
.contract__link{
    @apply sm:mb-5 lg:mb-0 sm:w-full lg:w-1/4 p-5 bg-blue-600 mr-5 rounded hover:bg-blue-500 transition-all ease-in-out duration-500
}
.contract__link_color_green{
    @apply bg-green-600 hover:bg-green-500
}
.contract__actions-wrapper{
    @apply border-t py-5
}
.contract__actions{
    @apply flex sm:flex-col lg:flex-row my-5 text-white justify-between
}
.request__actions{
 @apply flex  sm:flex-col lg:flex-row
}

.request__action{
  @apply sm:w-full py-5 px-3 mr-5 rounded sm:mt-5 lg:my-0
}
.request__action_confirm{
  @apply bg-green-600
}
.request__action_reject{
 @apply bg-red-600
}
</style>