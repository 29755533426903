<template>
    <div class="send">
        <button class="send__button" @click="show = true" >
            Выслать логин и пароль
        </button>
        <ModalWindow v-show="show" class="overflow-auto">
            <div class="send__modal-content">
                <h2 class="send__title">
                    Выслать пользователю логин и новый пароль?
                </h2>
                <p class="send__code">Для подтверждения операции введите указанный код: <span
                    class="text-blue-500">{{ codeText }}</span></p>
                <input v-model="codeValue" class="send__input" type="text" placeholder="Код подтверждения">
                <div class="send__actions">
                    <button class="send__action send__action_submit" @click="submit"  :disabled="!confirmed"
                            :class="{'bg-green-400 cursor-pointer':confirmed,'bg-gray-500 cursor-default':!confirmed}">
                        Отправить</button>
                    <button class="send__action send__action_cancel" @click="close">Отмена</button>
                </div>

            </div>
        </ModalWindow>
    </div>

</template>

<script>
import ModalWindow from '@/components/common/ModalWindow'
import {getRandomInt} from "../../../helpers/functions";

export default {
    name: "SendLoginAndPass",
    components:{
        ModalWindow
    },
    data:()=>({
        show:false,
        codeText:'',
        codeValue:''
    }),
    props:{
        id:{
            required:true
        }
    },
    computed:{
        confirmed() {
            return Number.parseInt(this.codeValue) === Number.parseInt(this.codeText);
        },
    },
    created() {
        this.codeText = getRandomInt(1000, 9999)
    },
    methods:{
        submit(){
            if(this.confirmed){
                this.$store.dispatch('admin/sendLoginPassword', {id:this.id})
                    .then(res => {
                        this.$notify({
                            title: 'Успешно',
                            text: res.data.data,
                            type: 'success'
                        })
                        this.close()
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Ошибка',
                            text: err.response.data.message,
                            type: 'error'
                        })
                    })
            }

        },
        close(){
            this.show = false
            this.codeText = getRandomInt(1000, 9999)
            this.codeValue = ''
        }
    }
}
</script>

<style scoped>
.send__button{
    @apply sm:w-full p-5  mr-5 rounded bg-red-600
}
.send__modal-content{
    @apply lg:w-1/3 sm:w-full  dark:bg-black bg-white dark:text-white text-black  p-10 rounded relative lg:border-l-2
}
.send__code{
    @apply select-none w-1/2
}
.send__actions{
    @apply my-5
}
.send__action{
    @apply px-5 py-2.5 rounded text-white mr-2
}
.send__action_cancel{
    @apply bg-red-600
}
.send__code{
    @apply my-2
}
.send__input {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white my-2
}
</style>